// Handles the tabbed interactions for the testimonial module

export default function () {

  window.onload = function() {

    if(document.querySelector('.c-testimonials__stage')){
  
      const tabs = document.querySelector('.c-testimonials__stage');
      const tabButtons = tabs.querySelectorAll('[role="tab"]');
      const tabPanels = Array.from(tabs.querySelectorAll('[role="tabpanel"]'));

      const test = document.querySelector('.navigation li');
      const test1 = document.querySelectorAll('.navigation li');

      function handleTabClick(e) {
        const { id } = e.currentTarget;

        // hide all tab panels
        tabPanels.forEach(panel => {
          panel.hidden = true
        });
        // mark all tabs as unselected
        tabButtons.forEach(button => {
          button.setAttribute('aria-selected', false);
        });
        // mark the clicked tab as selected
        e.currentTarget.setAttribute('aria-selected', true);
        // find the associated tabPanel and show it
        const tabPanel = tabPanels.find(panel => panel.getAttribute('aria-labelledby') === id);
        tabPanel.hidden = false;
        
      };
      
      tabButtons.forEach(button => button.addEventListener('click', handleTabClick));
    
    } // end if

  }
}
